import { Helmet } from 'react-helmet-async';
import Markdown from 'react-markdown/with-html';
import {
  Box,
  Container, Grid,
  Typography
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import gtm from '../../lib/gtm';

const MarkdownWrapper = experimentalStyled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& h2': {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.h2.lineHeight,
    marginBottom: theme.spacing(3)
  },
  '& h3': {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.h3.lineHeight,
    marginBottom: theme.spacing(3)
  },
  '& p': {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(2)
  },
  '& li': {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(1)
  },
  '& a': {
    color: theme.palette.primary.light,
  },
}));

const BlogPost = ({
  title,
  subtitle,
  content,
  locale
}) => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 15
        }}
      >
        <Container
          locale={locale}
          maxWidth="lg"
        >
          <Grid
            alignItems="center"
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                variant="h1"
                style={{ wordWrap: 'break-word' }}
              >
                {title}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{ mt: 1 }}
                variant="body1"
              >
                {subtitle}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: {
                  md: 'flex',
                  xs: 'none'
                },
                justifyContent: 'center'
              }}
            >
              <img
                alt="Components"
                src="/static/browse/hero.svg"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          minHeight: '100%'
        }}
      >
        <Box sx={{ py: 3 }}>
          <Container maxWidth="md">
            <MarkdownWrapper>
              <Markdown source={content} />
            </MarkdownWrapper>
          </Container>
        </Box>
      </Box>
    </>
  );
};

BlogPost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  locale: PropTypes.any,
};

export default BlogPost;
