import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import MinusIcon from '../icons/Minus';
import { useTranslation } from 'react-i18next';
import Lockup from './Lockup';

const getSections = (i18n) => (
  [
    {
      title: i18n.t('footer:section.legal'),
      links: [
        {
          title: i18n.t('footer:legal.privacy-policy.label'),
          href: '/privacy-policy'
        }
      ]
    },
    {
      title: i18n.t('footer:section.social'),
      links: [
        {
          title: i18n.t('footer:social.twitter.label'),
          href: 'https://twitter.com/SOBITADA'
        }
      ]
    }
  ]
);

const Footer = (props) => {
  const i18n = useTranslation();
  const sections = getSections(i18n);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        pb: 6,
        pt: {
          md: 15,
          xs: 6
        }
      }}
      {...props}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: {
                md: 1,
                xs: 4
              }
            }}
            xs={12}
          >
            <Link href="https://staking.outofbits.com">
              <Lockup
                sx={{
                  width: 200,
                  height: 200,
                }}
              />
            </Link>
          </Grid>
          {sections.map((section, index) => (
            <Grid
              item
              key={section.title}
              md={3}
              sm={4}
              sx={{
                order: {
                  md: index + 2,
                  xs: index + 1
                }
              }}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {section.title}
              </Typography>
              <List disablePadding>
                {section.links.map((link) => (
                  <ListItem
                    disableGutters
                    key={link.title}
                    sx={{
                      pb: 0,
                      pt: 1
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        minWidth: 0,
                        mr: 0.5
                      }}
                    >
                      <MinusIcon
                        color="primary"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <Link
                          href={link.href}
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {link.title}
                        </Link>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
        <Divider
          sx={{
            borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
            my: 6
          }}
        />
        <Typography
          color="textSecondary"
          variant="caption"
        >
          All Rights Reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
