import BlogPost from '../BlogPost';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const i18n = useTranslation();
  return (
    <BlogPost
      title={i18n.t('privacy:title')}
      subtitle={i18n.t('privacy:subtitle')}
      content={i18n.t('privacy:content')}
    />
  );
};

export default PrivacyPolicy;
